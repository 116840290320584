import { defineAbility } from '@casl/ability'

import { User } from '../../models'
import { Roles, userHasRole } from '../../helpers'

export default (user: User) => defineAbility((can) => {
    if (userHasRole(user, Roles.Standard)) {
        can('Create', 'Supplier')
        can('Edit', 'SupplierStatus')
        can('View', 'SupplierRecordings')
    }

    if (userHasRole(user, Roles.Contributor)) {
        can('Edit', 'Supplier')
        can('View', 'SupplierBalances')
    }

    if (userHasRole(user, Roles.CollectionAgent)) {
        can('View', 'SupplierCallbacks')
        can('View', 'SupplierCollections')
        can('Create', 'CollectionsContact')
        can('Edit', 'CollectionsContact')
        can('Delete', 'CollectionsContact')
        can('Delete', 'SupplierRecording')
        can('Create', 'SupplierInvoice')
    }

    if (userHasRole(user, Roles.Lead)) {
        can('Delete', 'Supplier')
    }

    if (userHasRole(user, Roles.Executive)) {
        can('Upload', 'SupplierData')
        can('Edit', 'VendorCode')
    }

    if (userHasRole(user, Roles.SrLead)) {
        can('AssignToUser', 'Supplier')
        can('Delete', 'SupplierNote')
        can('Upload', 'SupplierData')
        can('Delete', 'SupplierRecording')
        can('Create', 'SupplierInvoice')
        can('Filter', 'SupplierCollections')
    }
})
