import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import { createSupplierCallback, getSupplierCallbacks, deleteSupplierCallback, getCallbacksCalendarId } from '../../api';
import { User, SupplierCallback, JsonSupplierCallback } from '../../models';
import { gapi } from 'gapi-script';

interface ScheduleCallbackFormProps {
    supplierId: number
    supplierName: string
    users: User[]
}

export const ScheduleCallbackForm = (props: ScheduleCallbackFormProps) => {
    const [scheduledTime, setScheduledTime] = useState<Dayjs | null>(null)
    const [reason, setReason] = useState<string>('')
    const [assignedTo, setAssignedTo] = useState<number | ''>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [supplierCallbacks, setSupplierCallbacks] = useState<SupplierCallback[]>([])
    
    const [calendarId, setCalendarId] = useState<string>('')
    const [apiKey, setApiKey] = useState<string>('')
    const [clientId, setClientId] = useState<string>('')
    const [scope, setScope] = useState<string>('')

    const users = props.users

    const initializeGapiClient = (apiKey: string, clientId: string, scope: string) => {
        return gapi.client.init({
            apiKey: apiKey,
            clientId: clientId,
            scope: scope,
            discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
        }).then(() => {
            const authInstance = gapi.auth2.getAuthInstance()
            if (authInstance && !authInstance.isSignedIn.get()) {
                return authInstance.signIn().then(() => {
                    console.log('User signed in automatically')
                })
            }
        }).catch((error: any) => {
            console.error('Error initializing gapi.client:', error)
        })
    }

    useEffect(() => {
        getCallbacksCalendarId()
            .then(response => {
                setCalendarId(response.data.calendarId)
                setClientId(response.data.clientId)
                setApiKey(response.data.apiKey)
                setScope(response.data.scope)
            })
            .catch(error => toast.error(error, { autoClose: false }))
    }, [])

    useEffect(() => {
        getSupplierCallbacks(props.supplierId.toString())
            .then(response => {
                if (response.status === 200) {
                    setSupplierCallbacks(response.data.map(
                        (callback: JsonSupplierCallback) => new SupplierCallback(callback))
                    )
                }
            })
            .catch(error => toast.error(error, { autoClose: false }))
    }, [props.supplierId, loading])

    useEffect(() => {
        if (clientId && apiKey && scope) {
            gapi.load('client:auth2', () => {
                initializeGapiClient(apiKey, clientId, scope)
            })
        }
    }, [clientId, apiKey, scope])

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        if (!scheduledTime || !reason.trim() || !assignedTo) {
            toast.error('Please fill in all required fields.')
            return
        }

        setLoading(true)
        try {
            await createSupplierCallback({
                scheduledTime: scheduledTime.format(),
                reason: reason,
                supplierId: props.supplierId,
                assignedTo: Number(assignedTo),
            })

            const event = {
                summary: `Callback for ${props.supplierName} assigned to ${users.find(user => user.id === assignedTo)?.name}`,
                start: {
                    dateTime: scheduledTime.toISOString(),
                    timeZone: 'UTC',
                },
                end: {
                    dateTime: scheduledTime.add(1, 'hour').toISOString(),
                    timeZone: 'UTC',
                },
                description: reason,
            }

            const request = gapi.client.calendar.events.insert({
                calendarId: "primary",
                resource: event,
            })

            const requestGroup = gapi.client.calendar.events.insert({
                calendarId: calendarId,
                resource: event,
            })
            
            request.execute((event: { htmlLink: string; }) => {
                console.log('Individual event created: ' + event.htmlLink)
            })

            requestGroup.execute((event: { htmlLink: string; }) => {
                console.log('Group event created: ' + event.htmlLink)
            })
          
            setLoading(false)
            toast.success('Callback scheduled successfully.')
        } catch (error) {
            setLoading(false)
            toast.error('Failed to schedule callback.')
        }
    }

    const handleDelete = async (callbackId: number) => {
        setLoading(true)
        try {
            await deleteSupplierCallback(callbackId)
            setSupplierCallbacks(supplierCallbacks.filter(callback => callback.id !== callbackId))
            setLoading(false)
            toast.success('Callback deleted successfully.')
        } catch (error) {
            setLoading(false)
            toast.error('Failed to delete callback.')
        }
    }

    return (
        <div className='card p-4'>
            <h3>Schedule Call Back</h3>
            <div className='schedule-callback-form'>
                <form onSubmit={handleSubmit}>
                    <div className='form-group mb-3'>
                        <label htmlFor='assignedTo'>Assign To</label>
                        <select
                            id='assignedTo'
                            className='form-control'
                            value={assignedTo}
                            onChange={(e) => setAssignedTo(e.target.value === '' ? '' : Number(e.target.value))}
                            required
                        >
                            <option value=''>Select a User</option>
                            {users.filter(user => user.roles.some(role => role.name === 'collectionagent')).map((user) => (
                                <option key={user.id} value={user.id}>
                                    {user.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='form-group mb-3'>
                        <label htmlFor='reason'>Reason</label>
                        <textarea
                            id='reason'
                            className='form-control'
                            value={reason}
                            onChange={(e) => setReason(e.target.value.slice(0, 255))}
                            required
                        />
                    </div>
                    <div className='form-group mb-3'>
                        <label htmlFor='scheduledTime'>Scheduled Time</label>
                        <input
                            type='datetime-local'
                            id='scheduledTime'
                            onChange={(e) => {
                                if (e.target.value) {
                                    setScheduledTime(dayjs(e.target.value))
                                } else {
                                    setScheduledTime(null)
                                }
                            }}
                            value={scheduledTime ? scheduledTime.format('YYYY-MM-DDTHH:mm') : ''}
                            className='form-control'
                            required
                        />
                    </div>
                    <button type='submit' className='btn btn-primary' disabled={loading}>
                        {loading ? 'Scheduling...' : 'Schedule Callback'}
                    </button>
                </form>
            </div>
            <div className='callbacks-list pt-4'>
                <h3 className='mb-3'>Scheduled Callbacks</h3>
                {supplierCallbacks.length > 0 ? (
                    <ul className='list-group'>
                        {supplierCallbacks.sort((a, b) => dayjs.utc(a.scheduledTime).unix() - dayjs.utc(b.scheduledTime).unix()).map((callback) => {
                            return (
                                <li key={callback.id} className='list-group-item d-flex justify-content-between align-items-start'>
                                    <div className='ms-2 me-auto'>
                                        <div className='fw-bold'>
                                            Time: {dayjs.utc(callback.scheduledTime).local().format('YYYY-MM-DD hh:mm')}
                                        </div>
                                        <div>Assigned To: {users.find(user => user.id === callback.assignedTo)?.name}</div>
                                        <div>Reason: {callback.reason}</div>
                                    </div>
                                    <button onClick={() => handleDelete(callback.id)} className='btn btn-danger btn-sm'>
                                        Delete
                                    </button>
                                </li>
                            )
                        })}
                    </ul>
                ) : (
                    <p>No callbacks scheduled.</p>
                )}
            </div>
        </div>
    )
}
