import React, { Dispatch, useState, SetStateAction } from 'react'
import { AnyMongoAbility } from '@casl/ability';

import { Agency } from '../../models'
import { EditAgencyForm } from './edit'
import { AgencyTable } from './resourceTable'

interface AgencyCardProps {
    ability: AnyMongoAbility
    agency: Agency
    setAgency: Dispatch<SetStateAction<Agency | undefined>>
}

export const AgencyCard = (props: AgencyCardProps) => {
    const { ability, agency, setAgency } = props

    const [isEditing, setIsEditing] = useState(false)

    return (
        <div className='container card mb-3'>
            <div className='card-body row d-flex flex-row justify-content-between mb-5'>
                <div className='row flex-row mb-5'>
                    <h3 className='card-title d-inline-flex ps-0  w-50'>
                        {agency.name}
                    </h3>
                    <div className='pe-0 d-inline-flex justify-content-end w-50'>
                        <button
                            className='btn btn-primary'
                            disabled={!ability.can('Edit', 'Agency')}
                            onClick={() => setIsEditing(!isEditing)}>
                            Toggle Edit
                        </button>
                    </div>
                </div>
                {!isEditing && <AgencyTable agency={agency} />}
                {isEditing && (
                    <EditAgencyForm
                        agency={agency}
                        setIsEditing={setIsEditing}
                        setAgency={setAgency} />
                )}
            </div>
        </div>
    )
}
